<template>
  <div class="travel">
    <Header />
    <van-swipe :autoplay="3000" class="travel-swiper" style="margin-top: 50px">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <img :src="item.url" class="travel-banner" />
      </van-swipe-item>
    </van-swipe>
    <div v-if="containerList.length>0" class="title">
      <div v-for="item in containerList" class="title-box" :key="item.id">
          <div class="title-text" @click="handleClickChangeDetails(item)">{{h5Language=='En'?item.name:item.nameEn}}</div>
          <div class="line" v-if="active==item.id"></div>
      </div>
    </div>
    <div class="main">
      <div v-if="active==28">
        <div v-for="item in artList" :key="item.id" class="main-box">
          <div class="main-title">
            {{h5Language=='En'?item.title:item.titleEn}}
          </div>
          <div class="main-synopsis">
            {{h5Language=='En'?item.synopsis:item.synopsisEn}}
          </div>
          <img class="main-img" :src="item.imgUrl" alt="">
        </div>
        <div class="main-swipe">
          <div class="swipe-header">
            <div class="header-left"></div>
            <div class="header-title">{{h5Language=='En'?'荣誉奖项':'Honorary Award'}}</div>
          </div>

          <div>
            <van-swipe  @change="onChange" :autoplay="2000" class="travel-swiper" :show-indicators="false">
              <van-swipe-item v-for="(item, index) in imgList" :key="index">
                <img :src="item.imgUrl" class="travel-banner" />
              </van-swipe-item>
              <div class="custom-indicator" slot="indicator">
                {{ current + 1 }}/{{total}}
              </div>
            </van-swipe>
          </div>
        </div>
      </div>
      <div v-if="active==29">
        <div class="main-swipe">
          <div class="swipe-header">
            <div class="header-left"></div>
            <div class="header-title">{{h5Language=='En'?'鑫桥架构图':'Xinqiao Architecture Drawing'}}</div>
          </div>
          <img class="framework" src="../assets/home/download@2x.png" alt="">
        </div>
        <div class="main-swipe">
          <div class="swipe-header">
            <div class="header-left"></div>
            <div class="header-title">{{h5Language=='En'?'鑫桥子公司':'Xinqiao Subsidiary'}}</div>
          </div>
          <div class="son">
            <div class="son-company" v-for="item in sonList" :key="item.id" @click="handleCompanyClick(item)">
<!--              <img src="../assets/home/logo2@2x.png" alt="" @click="handleClickTogo(item)">-->
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="active==30">
        <img class="dev" src="../assets/home/dev@2x.ae70887b@2x.png" alt="">
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '../components/header'
import Footer from "../components/footer";
import { getBanner, cloumnTree,getArticleChoose,getPrizeList,getSubsidiaryList } from '../api'

export default {
  name: 'Xqstore',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      list: [],
      containerList: [],
      active:28,
      artList:[],
      sonList:[],
      h5Language:'En',
      imgList:[],
      current: 0,
      total:0
    }
  },
  mounted () {
    this.h5Language = localStorage.getItem('h5Language')||'En'
    this.cloumnId = this.$route.query.id||''
    if(this.$route.query.listId){
      this.cloumnId = this.$route.query.listId
    }
    this.active = this.$route.query.anchorId||28
    this.getBanner()
    this.cloumnTree()
    this.getArticleChoose()
    this.getPrizeList()
    this.getSubsidiaryList()
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    handleClickTogo(item){
      window.location.href=`${item.url}`
    },
    getSubsidiaryList(){
      getSubsidiaryList().then(res=>{
        if(res.code==200){
          this.sonList = res.data
        }
      })
    },
    getArticleChoose() {
      this.artList = []
      getArticleChoose({cloumnId: this.active, parentId: this.cloumnId}).then(res => {
        if (res.code == 200) {
          this.artList = res.data
        }
      })
    },
    getPrizeList(){
      getPrizeList().then(res=>{
        if(res.code==200){
          this.$nextTick(()=>{
            this.imgList = res.data
            this.total= res.data.length
          })

        }
      })
    },
    handleClickChangeDetails(item){
      this.active = item.id
      this.getArticleChoose()
    },
    cloumnTree () {
      cloumnTree().then(res => {
        res.data.forEach(e => {
          if (e.id == this.cloumnId) {
            console.log(e.id, this.cloumnId, 222222)
            if (e.children && e.children.length > 0) {
              this.containerList = e.children
              console.log(this.containerList, '111111')
            }
          }
        })
      })
    },
    getBanner () {
      getBanner({ cloumnId: this.cloumnId }).then(res => {
        this.list = res.data
        console.log(res, '我是返回的resid')
      })
    },
    handleCompanyClick(item) {
      const query = {
        name: item.name,
        desc: item.synopsis
      }
      this.$router.push({ path: '/company', query})
    }
  }
}
</script>

<style lang="scss" scoped>
  .travel{
    width: 100%;
    position: relative;
    .travel-banner{
     width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .travel-swiper{
      height: 150px;
      width: 100%;

    }
    .title{
      position: absolute;
      top: 128px;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      width: 327px;
      margin: 0 auto;
      .title-box{
        width: 109px;
        height: 44px;
        border: 1px solid rgba(151, 151, 151, 0.5);
        background: #002E73;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .title-text{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        position: relative;
      }
      .line{
        position: absolute;
        bottom: 6px;
        width: 28px;
        border-radius: 1px;
        height: 2px;
        background: #FFFFFF;
      }
    }
    .title .title-box:first-child{
      border-radius: 4px 0px 0px 4px;
    }
    .title .title-box:last-child{
      border-radius: 0px 4px 4px 0px;
    }
    .main{
      margin-top: 54px;
      padding: 0 24px;
      margin-bottom: 84px;
      .main-box{
        overflow: hidden;
      }
      .main-title{
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #4A4A4A;
        line-height: 22px;
        margin-bottom: 12px;
      }
      .main-synopsis{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4A4A4A;
        line-height: 20px;
        height: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: box;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        margin-bottom: 20px;
      }
      .main-img{
        width: 100%;
        height: 135px;
        object-fit: cover;
        margin-bottom: 20px;
        border-radius: 10px;
      }
      .main-swipe{
        overflow: hidden;
        margin-bottom: 30px;
        .swipe-header{
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          .header-left{
            width: 3px;
            height: 17px;
            background: #254D88;
          }
          .header-title{
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #254D88;
            line-height: 22px;
            margin-left: 10px;
          }
        }
        .son{
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
          .son-company{
            width: 100%;
            height: 47px;
            background: rgba(0,70,156,0.14);
            border-radius: 10px;
            border: 1px solid #00469C;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #00469C;
            font-size: 13px;
            padding: 6px;
            text-align: center;
            font-weight: 500;
            // 奇数
            &:nth-child(odd){
              margin-right: 10px;
            }
            img{
              width: 104px;
              height: 24px;
            }
          }
        }

      }
      .framework{
        height: 158px;
        width: 100%;
      }
    }
    .dev{
      width: 100%;
      height: 328px;
    }
    .custom-indicator {
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: 2px 5px;
      font-size: 12px;
      background: rgba(0, 0, 0, 0.1);
      color: #FFFFFF;
    }
  }
</style>
